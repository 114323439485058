import { Format } from "react-data-table-component/dist/src/DataTable/types";
import { Customer, emptyCustomer } from "./Customers";
import { Machine, emptyMachine } from "./Machine";
import { User, emptyUser } from "./User";
import moment from 'moment';

// temporary added by alvin
import divisionData from "../data/mappingData.json";

export const CLOSED_STATUS_THRESHOLD = 8;
export const CLOSED_STATUSES = [8,9];
export const CLOSE_STATUS_CHECK = (status:number) => {
    // returns true if status/number is considered closed.
    return CLOSED_STATUSES.includes(status)
}

export interface ServiceCall {
  id?: string;
  external_id?: string;
  assigned_to?: string;
  created_by?: User;
  machine_id?: string;
  description?: string;
  status: number;
  created?: string;
  status_updated?: string;
  date_completed?: string;
  is_callback: boolean;
  division: number;
  call_type?: number;
  vip: boolean;
  po_number?: string;
  caller?: string;
  caller_details?: string;
}

export const emptyServiceCall = ():ServiceCall => {
    return {
        id: undefined,
        status: 0,
        is_callback: false,
        division: 0,
        vip: false
    }
}

export type FormattedServiceCall = {
    id?: string;
    assigned_to?: User,
    created: string;
    time_since_creation: string;
    time_since_last_update: string;
    created_by_email?: string;
    created_by_name?: string;
    description?: string;
    machine?: Machine;
    customer?: Customer;
    status?: string;
    call: ServiceCall
}

export const emptyFormattedServiceCall = ():FormattedServiceCall => {
    return {
        id: "",
        assigned_to: emptyUser(),
        created: "",
        time_since_creation: "",
        time_since_last_update: "",
        created_by_email: "",
        created_by_name: "",
        description: "",
        machine: emptyMachine(''),
        customer: emptyCustomer(),
        status: "",
        call: emptyServiceCall()
    }
}

export const serviceCallToFormattedServiceCall = (servicecall:ServiceCall, machine:Machine, customer:Customer, assignee:User):FormattedServiceCall => {
    return {
        id: servicecall.id,
        assigned_to: assignee,
        created: moment(servicecall.created).toLocaleString(),
        time_since_creation: moment(servicecall.created).fromNow(),
        time_since_last_update: moment(servicecall.status_updated).fromNow(),
        created_by_email: servicecall.created_by?.email,
        created_by_name: servicecall.created_by?.name,
        description: servicecall.description,
        machine: machine,
        customer: customer,
        status: serviceCallStatusDescription(servicecall.status),
        call: servicecall
    }
}

export const SERVICE_CALL_TYPE_ENUM = [
    "Installation", 
    "Networking", 
    "Machine_Operative", 
    "Machine_Inoperative", 
    "Tech_Assist", 
    "In_Shop_Call",
    "Pick_Up",
    "Move",
    "Supplies_Delivery"
]

export const serviceCallTypeDescription = (status:number) => {
    const values =  SERVICE_CALL_TYPE_ENUM

    if (status < 0 || status > (values.length -1)) {
        return "UNKNOWN"
    }
    
    return values[status];
}

// eslint-disable-next-line no-sparse-arrays
export const SERVICE_CALL_STATUS_ENUM = [
    "Pending",
    , // "Active" should not appear anymore (1)
    "In_Transit",
    "On_Site",
    "Hold_Parts",
    "Hold_Credit",
    "Parts_Available",
    "Parts_Warranty",
    "Closed_Completed",
    "Cancelled",
    "Need_More_Time",
    "Tech_Assist",
    "In_Shop_Call",
    "Hold_Supplies",
    "Waiting_For_Approval",
    "Costs_Approved",
    "Parts_Processing"
];

export const serviceCallStatusDescription = (status:number) => {
    const values =  SERVICE_CALL_STATUS_ENUM

    if (status < 0 || status > (values.length -1)) {
        return "UNKNOWN"
    }
    
    return values[status];
}

export interface ServiceCallLog {
    id?: string;
    service_call_id?: string;
    action: number;
    details?: string;
    user: User;
    created: string;
}

export const serviceCallLogActionDescription = (status:number) => {
    const values =  [
        "CREATED",
        "STATUS_UPDATED",
        "EDITED"
    ];

    if (status < 0 || status > (values.length -1)) {
        return "UNKNOWN"
    }
    
    return values[status];
}

export const SERVICE_CALL_DIVISION_ENUM = [
    "ABD",
    "RWS",
    "D&A",
    "PCG",
    "TOM",
    "TOM_PH",
]

export const serviceCallDivisionDescription = (division:number) => {
    const values =  SERVICE_CALL_DIVISION_ENUM;
    if (division < 0 || division > (values.length -1)) {
        return "UNKNOWN"
    }
    
    return values[division];
}

export interface CallItem {
  id?: string;
  service_call_id: string;
  category: number;
  category_id: number | string;
  item_id: number | string;
  mapping: string;
  description?: string;
  quantity: number;
  amount: number;
  user?: User;
  created: string;
  deleted: boolean;
}

export const emptyCallItem = (svc:string):CallItem => {
    return {
        service_call_id: svc,
        quantity: 1,
        category: 0,
        category_id: "",
        amount: 0,
        deleted: false,
        item_id: "",
        mapping: '',
        created: ''
    }
}

export const callItemCategory = (status:number) => {
    const values =  [
        "PART",
        "LABOR",
        "OTHER",
        "NETWORKING",

        "COPIER SERVICE",
        "PRINTER SERVICE",
        "IT REMOTE SERVICE", 
        "IT ON SITE SERVICE",
        "KFS/FMA INSTALLATION"
    ];

    if (status < 0 || status > (values.length -1)) {
        return "UNKNOWN"
    }
    
    return values[status];
}

export interface CallNote {
    id?: string;
    service_call_id?: string;
    note: string;
    user?: User;
    created?: string;
}

export const emptyCallNote = (note: string, callid: string): CallNote => {
    return  {
        note,
        service_call_id: callid
    };
}

// added by alvin
export const getItemName = (div_id: string | number, cat_id: string | number, item_id: string | number) => {
    const ObjRef = divisionData.divisions.find(objDiv => objDiv.id === div_id)?.categories.find((objCat) => objCat.id === cat_id)
    ?.items.find((objItem) => objItem.id === item_id);
    if (ObjRef){
        return ObjRef.name;
    } else {
        return 'No Item';
    }
}
  